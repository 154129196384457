<template>
   <div class="modal-account">
      <div class="modal-account__inner">
         <keep-alive>
            <component :is="modal.component" @form-data="setFormData" />
         </keep-alive>

         <div class="modal-account__auth-social-block auth-social-block">
            <h3 class="auth-social-block__title">Войти как пользователь</h3>

            <ul class="auth-social-block__list">
               <li
                  v-for="(network, idx) in socialNetworks"
                  :key="idx"
                  class="auth-social-block__item"
               >
                  <a :href="network.link" class="auth-social-block__link">
                     <img :src="network.img" :alt="network.hint" class="auth-social-block__img" />
                  </a>
               </li>
            </ul>
         </div>
      </div>

      <button :form="modal.form" class="modal-account__btn btn">{{ modal.buttonText }}</button>

      <div class="form-selection">
         <button
            v-for="(item, idx) in formSwitches"
            :key="idx"
            :class="{
               'form-selection__btn--active': modal.component === item.component
            }"
            @click="setModal(item.component)"
            class="form-selection__btn btn"
         >
            {{ item.text }}
         </button>
      </div>
   </div>
</template>

<script>
   import ModalAuth from './modules/ModalAuth';
   import ModalRegistration from './modules/ModalRegistration';

   export default {
      name: 'ModalAccount',

      components: {
         ModalAuth,
         ModalRegistration
      },

      emits: {
         'update:title': (value) => typeof value === 'string'
      },

      data() {
         return {
            modal: { component: 'ModalAuth' },
            formSwitches: [
               {
                  component: 'ModalAuth',
                  text: 'Авторизация'
               },
               {
                  component: 'ModalRegistration',
                  text: 'Регистрация'
               }
            ],

            socialNetworks: [
               {
                  hint: 'Авторизация через Instagram',
                  link: '#',
                  img: require('@/assets/images/icons/instagram.webp')
               },
               {
                  hint: 'Авторизация через Facebook',
                  link: '#',
                  img: require('@/assets/images/icons/facebook.webp')
               },
               {
                  hint: 'Авторизация через Вконтакте',
                  link: '#',
                  img: require('@/assets/images/icons/vk.webp')
               }
            ]
         };
      },

      methods: {
         setFormData(value) {
            this.modal = value;
            this.$emit('update:title', value.title);
         },

         setModal(value) {
            this.modal.component = value;
         }
      }
   };
</script>
