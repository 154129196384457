<template>
   <form @submit.prevent="submitForm" class="auth-form form" action="#" id="auth-form" novalidate>
      <h3 class="auth-form__title">Войти как пользователь</h3>

      <div class="auth-form__inner">
         <app-field
            v-for="field in fields"
            :key="field.name"
            :is-form-submission="isFormSubmission"
            :is-displayed-error="isDisplayedError"
            :settings="field"
            @result-checking="saveResultChecking"
            @form-reset="resetForm"
         />
      </div>

      <div class="auth-form__wrap">
         <label class="remember-me">
            <input class="remember-me__input" type="checkbox" data-custom-checkbox="true" />
            <span class="remember-me__text">Запомните меня</span>
         </label>

         <a class="auth-form__recovery" href="#">Забыли пароль?</a>
      </div>
   </form>
</template>

<script>
   import AppField from '@/components/templates/FormFields/AppField';

   export default {
      name: 'ModalAuth',

      components: {
         AppField
      },

      emits: {
         'form-data': (value) => typeof value === 'object'
      },

      inject: ['openModal'],

      data() {
         return {
            isFormSubmission: false,
            isDisplayedError: false,
            fieldsValidationResults: [],

            fields: [
               {
                  name: 'Email',
                  type: 'email',
                  validationType: 'Email',
                  placeholder: 'E-mail'
               },
               {
                  name: 'password',
                  type: 'password',
                  validationType: 'Password',
                  placeholder: 'Пароль',
                  params: { minLength: 8, maxLength: 16 }
               }
            ],

            modalSettings: {
               name: 'ModalNotification',
               iconName: 'warning',
               message: 'Авторизация временно недоступна',
               isActive: true
            }
         };
      },

      activated() {
         this.$emit('form-data', {
            component: 'ModalAuth',
            form: 'auth-form',
            title: 'Авторизация',
            buttonText: 'Вход'
         });
      },

      methods: {
         saveResultChecking(value) {
            const field = this.fieldsValidationResults.find(
               (item) => item.name === value.name && item.type === value.type
            );

            if (field) {
               field.isValid = value.isValid;
            } else {
               this.fieldsValidationResults.push(value);
            }
         },

         resetForm(value) {
            this.isFormSubmission = value;
         },

         submitForm() {
            if (this.isValidFieldValue) {
               this.isFormSubmission = true;
               this.isDisplayedError = false;

               setTimeout(() => {
                  this.openModal(this.modalSettings);
               }, 200);
            } else {
               this.isDisplayedError = true;
            }
         }
      },

      computed: {
         isValidFieldValue() {
            return (
               this.fieldsValidationResults.length &&
               this.fieldsValidationResults.every((item) => item.isValid)
            );
         }
      }
   };
</script>
